<div class="modal-header">
	<span class="modal-title">Search</span>

	<button type="button" class="close" aria-label="Close" (click)="onClose.emit()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div class="d-flex flex-column phd-search-container" [class.phd-search-closed]="!optionsShown"
		 [class.phd-results-opened]="resultsShown">
		<sales-community-selector (onMarketChange)="onMarketChange($event)"
								  (onSalesCommunityChange)="onCommunityChange($event)"
								  (onFinancialCommunityChange)="onFinancialCommunityChange($event)"
								  (onFinancialCommunitiesForMarketChange)="onFinancialCommunitiesForMarket($event)"
								  showFinancialCommunity="true"></sales-community-selector>

		<div class="phd-search-options">

			<section *ngIf="!!optionsShown">
				<div (keyup.enter)="search()">
					<div class="phd-flexbox">
						<label>Lot #</label>
						<input type="text" [(ngModel)]="homesiteNumber" #homesiteField />
					</div>

					<div class="phd-flexbox">
						<label>Sales Agreement #</label>
						<input type="text" [(ngModel)]="salesAgreementNumber" #salesAgreementNumberField />
					</div>

					<div class="phd-flexbox">
						<label>Buyer First Name</label>
						<input type="text" [(ngModel)]="firstName" #firstNameField />
					</div>

					<div class="phd-flexbox">
						<label>Buyer Last Name</label>
						<input type="text" [(ngModel)]="lastName" #lastNameField />
					</div>
					<div class="phd-flexbox">
						<label>Street Address</label>
						<input type="text" [(ngModel)]="streetAddress" #streetAddressField />
					</div>
				</div>
			</section>

			<section *ngIf="!!optionsShown">
				<div>
					<div class="phd-flexbox mb-3">
						<label>Sales Agreement Status</label>
						<p-multiSelect defaultLabel="Select Agreement Status" [filter]="false" id="phdSalesStatus"
									   [maxSelectedLabels]="5" [(ngModel)]="selectedSalesAgreementStatus"
									   [options]="salesAgreementStatusOptions" (onPanelHide)="onOptionPanelClosed()">
						</p-multiSelect>
					</div>

					<div class="phd-flexbox mb-3">
						<label>Lot Status</label>
						<p-multiSelect defaultLabel="Select Lot Status" [filter]="false" id="phdHomesiteStatus"
									   [maxSelectedLabels]="5" [(ngModel)]="selectedHomesiteStatus"
									   [options]="homesiteStatusOptions" (onPanelHide)="onOptionPanelClosed()">
						</p-multiSelect>
					</div>

					<div class="phd-flexbox mb-3">
						<label>Build Type</label>
						<p-multiSelect defaultLabel="Select Build Type" [filter]="false" id="phdBuildType"
									   [maxSelectedLabels]="5" [(ngModel)]="selectedBuildTypes" [options]="buildTypesOptions"
									   (onPanelHide)="onOptionPanelClosed()">
						</p-multiSelect>
					</div>
				</div>
			</section>

			<section class="phd-buttons-holder" *ngIf="!!optionsShown">
				<div>
					<div class="w-100">
						<div>
							<button class="btn btn-primary align-self-start" (click)="searchPending()">
								All Pending
								Agreements
							</button>
						</div>
						<div>
							<button class="btn btn-primary align-self-start" (click)="searchReadyToClose()">Ready to Close Agreements</button>
						</div>
						<div>
							<button class="btn btn-primary align-self-start" (click)="searchPendingCOs()">All Pending COs</button>
						</div>
						<div>
							<button class="btn btn-primary align-self-start" (click)="searchAvailableSpecs()">Available Specs</button>
						</div>
					</div>
				</div>
			</section>

			<section *ngIf="!!!optionsShown">
				<span class="phd-badge" (click)="clearSearchFilters()">
					<strong>Clear Filters</strong>
				</span>
				<span class="phd-badge" (click)="editSearchCriteria()">
					<strong>Edit</strong>
				</span>

				<span class="phd-badge" *ngIf="homesiteNumber" (click)="edit('homesiteRef')">
					<span>Lot #</span>
					<strong>{{homesiteNumber}}</strong>
				</span>
				<span class="phd-badge" *ngIf="salesAgreementNumber" (click)="edit('salesAgreementNumberRef')">
					<span>Sales Agreement #</span>
					<strong>{{salesAgreementNumber}}</strong>
				</span>
				<span class="phd-badge" *ngIf="firstName" (click)="edit('firstNameRef')">
					<span>First Name</span>
					<strong>{{firstName}}</strong>
				</span>
				<span class="phd-badge" *ngIf="lastName" (click)="edit('lastNameRef')">
					<span>Last Name</span>
					<strong>{{lastName}}</strong>
				</span>
				<span class="phd-badge" *ngIf="streetAddress" (click)="edit('streetAddressRef')">
					<span>Street Address</span>
					<strong>{{streetAddress}}</strong>
				</span>
				<span class="phd-badge" *ngIf="selectedHomesiteTypes.length > 0" (click)="edit()">
					<span>Lot Type</span>
					<strong>{{selectedHomesiteTypes.join(', ')}}</strong>
				</span>
				<span class="phd-badge" *ngIf="selectedHomesiteStatus.length > 0" (click)="edit()">
					<span>Lot Status</span>
					<strong>{{ selectedHomesiteStatusList }}</strong>
				</span>
				<span class="phd-badge" *ngIf="!optionsShown && selectedBuildTypes.length > 0" (click)="edit()">
					<span>Build Type</span>
					<strong>{{selectedBuildTypes.join(', ')}}</strong>
				</span>
				<span class="phd-badge" *ngIf="!optionsShown && selectedSalesAgreementStatus.length > 0" (click)="edit()">
					<span>Agreement Status</span>
					<strong>{{ selectedSalesAgreementStatusList }}</strong>
				</span>
				<span class="phd-badge" *ngIf="searchActiveOnly" (click)="edit('searchActiveOnly')">
					<span>Pending CO</span>
				</span>
				<span class="phd-badge" *ngIf="readyToClose" (click)="edit()">
					<span>Ready to close</span>
				</span>
			</section>
		</div>

		<div class="d-flex px-4 pb-3 phd-search-button-holder">
			<div class="phd-filter">
				<button *ngIf="optionsShown" class="btn btn-primary" (click)="clearSearchFilters()" [disabled]="!isValid">
					Clear Filters
				</button>
			</div>
			<div class="phd-search">
				<button class="btn btn-primary" (click)="search()" [disabled]="!isValid" #searchButton>
					{{search_button_label || SEARCH_STATUS.READY}}
				</button>
			</div>
		</div>

		<div class="px-4 justify-content-center phd-search-results bg-white" *ngIf="searchResults">
			<div class="d-flex phd-search-results-holder">

				<phd-table #dt [value]="searchResults" tableId="searchResults" sortMode="single" [noRecordsMessage]="filterSpecs ? NO_RECORDS_MESSAGES.SPECS : NO_RECORDS_MESSAGES.SEARCH" [nonOverflow]="false">
					<!--Homesite #-->
					<phd-column field="homesiteNumber" header="Lot #"></phd-column>

					<!--Address (street 1, 2, unit #, city, state, country, postal code)-->
					<phd-column field="streetAddress1" header="Street Address" canSort="true">
						<ng-template #body let-lot>
							<div class="phd-street-unit"
								 *ngIf="lot.streetAddress1 || lot.streetAddress2 || lot.unitNumber">
								<span *ngIf="lot.streetAddress1">{{lot.streetAddress1}}</span>
								<span *ngIf="lot.streetAddress2">{{lot.streetAddress2}}</span>
								<span *ngIf="lot.unitNumber">{{lot.unitNumber}}</span>
							</div>
							<div class="phd-city-state-country" *ngIf="lot.city || lot.state || lot.country">
								<span *ngIf="lot.city">{{lot.city}}</span>
								<span *ngIf="lot.stateProvince">
									<span *ngIf="lot.city">,</span>
									{{lot.stateProvince}}
								</span>
								<span *ngIf="lot.country"> {{lot.country}}</span>
							</div>
							<div class="phd-zip" *ngIf="lot.postalCode">{{lot.postalCode}}</div>
						</ng-template>
					</phd-column>

					<!--Buyers-->
					<phd-column field="buyerString" header="Buyers"></phd-column>

					<!--build type-->
					<phd-column field="buildType" header="Build Type">
						<ng-template #body let-lot>
							<ng-container *ngIf="getBuildTypeDisplay(lot); else elseBody">
								<a href="{{getBuildTypeUrl(lot)}}" target="_blank"
								   [attr.title]="getLotBuildType(lot)">{{getLotBuildType(lot)}}</a>
							</ng-container>
							<ng-template #elseBody>
								<span [attr.title]="getLotBuildType(lot)">{{getLotBuildType(lot)}}</span>
							</ng-template>
						</ng-template>
					</phd-column>

					<!--Premium (cost)-->
					<phd-column header="Premium" field="premium">
						<ng-template #body let-lot>
							{{lot.premium | currency:'USD':'symbol':'0.0-0'}}
						</ng-template>
					</phd-column>

					<!--Homesite/Lot type-->
					<phd-column field="homesiteType" header="Lot Type"></phd-column>

					<phd-column field="lotStatusDescription" header="Lot Status"></phd-column>

					<!--Plans available with lot-->
					<phd-column header="Plans">
						<ng-template #body let-lot>
							<div *ngFor="let plan of lot.plans" [attr.title]="plan.name">{{plan.name}}</div>
						</ng-template>
					</phd-column>

					<!--Financial Community-->
					<phd-column field="financialCommunity" header="Financial Community" [canSort]="false"></phd-column>

					<!--Scenarios/Configuartions-->
					<phd-column header="Pre-Sale Configurations">
						<ng-template #body let-lot>
							<div *ngFor="let scenario of lot.scenarios">
								<a href="{{environment.baseUrl[action.envBaseUrl]}}edit-home/{{scenario.id}}"
								   target="_blank" *ngIf="scenario && scenario.name" [attr.title]="scenario.name">
									{{scenario.name}}
								</a>
							</div>
						</ng-template>
					</phd-column>

					<!--Every sales agreement within every change order group within one of the lot's jobs *whew* -->
					<phd-column header="Agreements">
						<ng-template #body let-lot>
							<!--If sold in Home Selections-->
							<em *ngIf="lot.lotStatusDescription === 'Sold' && !!!lot.salesAgreements.length">{{SOLD_IN_HOMES}}</em>

							<!--Otherwise loop through sales agreements if any-->
							<div *ngFor="let agreement of lot.salesAgreements">
								<a href="{{getSalesAgreementLink(agreement)}}" target="_blank" *ngIf="shouldDisplayAgreement(lot, agreement)" [attr.title]="agreement.salesAgreementNumber">
									{{agreement.salesAgreementNumber}} - {{ agreement.isLockedIn && agreement.status === 'Approved' ? 'Ready to Close' : agreement.status }}
								</a>
							</div>
						</ng-template>
					</phd-column>

					<phd-column header="Active Change Order">
						<ng-template #body let-lot>
							<!--If sold in Home Selections-->
							<span *ngIf="lot.activeChangeOrderText" class="phd-active-change-order">
								<a href="{{getChangeOrderLink(lot)}}" target="_blank" [attr.title]="lot.activeChangeOrderText">{{lot.activeChangeOrderText | ellipsis: 50}}</a>
							</span>
						</ng-template>
					</phd-column>
				</phd-table>
			</div>
			<div class="font-weight-bold p-1" *ngIf="searchResults">
				{{searchResults.length}} Lot<span *ngIf="searchResults.length != 1">s</span> returned.
			</div>
			<h5 class="pt-3 m-0 text-danger text-center" *ngIf="searchError">{{searchError}}</h5>
		</div>
	</div>
</div>
